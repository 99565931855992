import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import { createForm } from 'rc-form';
import Table from '../../../components/Table';
import Cell from '../../../components/Table/cell';
import Select from '@material-ui/core/Select';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Layout from '../../../components/v2/Layout';
import { getEmoriRelationPositionList, patchEmoriRelationMemberProfile, postEmoriRelationCreateMemberProfile } from '../../../apis/emori';
import { getUrlParams } from '../../../helpers/location';
import BirthDateInput from '../../../components/BirthDateInput';
import ProfileSelector from '../../../components/ProfileSelector';
import { baseProfileData } from '../../../helpers/emori';

const useStyles = makeStyles(theme => ({
  titleInput: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  tableSpace: {
    borderSpacing: '0px 20px',
    borderCollapse: 'separate'
  },
  topic: {
    marginBottom: '15px',
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'left',
    color: 'black',
  },
}));

const ProfileAdminEmori = ({ form, readOnly, loading, location }) => {
  const classes = useStyles();
  const { validateFields, getFieldDecorator, getFieldError } = form;
  const [ newValue, setNewValue] = React.useState('');
  const [ position, setPosition ] = React.useState([]);
  const [ imageURLs, setImageURLs ] = React.useState(baseProfileData);
  const [ imageFile, setImageFile ] = React.useState(null);
  const [ isImgChange, setIsImgChange ] = React.useState(false);
  const params = getUrlParams(location.search);
  const { corporate, room } = params;

  React.useEffect(() => {
    emori();
  }, []);

  const emori = async () => {
    try {
      const member = await getEmoriRelationPositionList(corporate, 'false');
      setPosition(member.results);
    } catch (e) {}
  }

  const onSubmit = async (payload) => {
    const mem = await postEmoriRelationCreateMemberProfile(room, payload);
    if (isImgChange) {
      const updateProfileImagePayload = new FormData();
        updateProfileImagePayload.append(
          'image', imageFile
        );
      await patchEmoriRelationMemberProfile(room, mem.id, updateProfileImagePayload);
      setIsImgChange(false);
    }
    await navigate(`/emori/relation/addProfileSuccess?corporate=${corporate}&room=${room}`, { replace: true })
  }

  const checkChange = () => {
    validateFields((error, value) => {
      if (!error) {
        const payload = {
          name: value.name,
          position: value.position,
          nickname: value.nickname,
          birthday: value.birthday,
        }
        onSubmit(payload);
      }
    });
  }

  return (
    <Layout bg='bg4' title=''>
      <Typography variant='h5' component='h5' className={classes.topic}>เพิ่มโปรไฟล์</Typography>
      <Table classes={{ root: classes.tableSpace }}>
        <TableBody>
          <TableRow>
            <Cell style={{ minWidth: '100px' }}><span className={classes.titleInput}>ชื่อ</span></Cell>
            <Cell>
              {
                getFieldDecorator('name', {
                  rules: [{ required: true }],
                })(
                  <Input
                    disabled={readOnly}
                    fullWidth
                    error={getFieldError('name')}
                  />
                )
              }
            </Cell>
          </TableRow>
          <TableRow>
            <Cell style={{ minWidth: '100px' }}><span className={classes.titleInput}>ชื่อเล่น</span></Cell>
            <Cell>
              {
                getFieldDecorator('nickname', {
                  rules: [{ required: true }],
                })(
                  <Input
                    disabled={readOnly}
                    fullWidth
                    error={getFieldError('nickname')}
                  />
                )
              }
            </Cell>
          </TableRow>
          <TableRow>
            <Cell style={{ minWidth: '100px' }}><span className={classes.titleInput}>วันเกิด</span></Cell>
            <Cell>
              {
                getFieldDecorator('birthday', {
                  rules: [{ required: true }],
                  getValueFromEvent: function (e) { return e }
                })(
                  <BirthDateInput
                    native
                    error={getFieldError('birthday')}
                  />
                )
              }
            </Cell>
          </TableRow>
          <TableRow>
            <Cell style={{ minWidth: '100px' }}><span className={classes.titleInput}>ตำแหน่ง</span></Cell>
            <Cell>
              {
                getFieldDecorator('position', {
                  rules: [{ required: true }],
                })(
                  <Select
                    disabled={readOnly}
                    className={classes.textInput}
                    fullWidth
                    native
                    error={getFieldError('position')}
                  >
                    <option value=''>เลือก</option>
                    {position.map((option) => (
                      <option value={option.id} key={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Select>
                )
              }
            </Cell>
          </TableRow>
          <TableRow>
            <Cell><span className={classes.titleInput}>รูปโปรไฟล์</span></Cell>
            <Cell>
              <ProfileSelector
                stateCurrentImgURLs={imageURLs}
                stateIsImgChange={isImgChange}
                onIsImgChange={setIsImgChange}
                onGetImgFile={setImageFile}
                onGetImgUrls={setImageURLs}
                usePreview={true}
              />
            </Cell>
          </TableRow>
        </TableBody>
      </Table>
      {
        !readOnly && (
          <Box mt={3}>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              disabled={loading}
              onClick={() => checkChange()}
            >
              ต่อไป
            </Button>
          </Box>
        )
      }
    </Layout>
  );
};

export default createForm()(ProfileAdminEmori);